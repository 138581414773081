<template>
  <layout :title="$route.name" :options="options" :rotas="rotas">
    <div
      style="min-height: 96vh; align-items: flex-start !important; justify-content: flex-start !important; overflow: auto;"
      class="expande-horizontal windows-style-content pr-3 pl-3 pt-3 wrap"
    >
      <div class="expande-horizontal wrap">
        <v-flex xs12 md9>
          <div class="expande-horizontal wrap">
            <v-flex class="windows-style pb-0" xs12>
              <v-list nav color="transparent" class="pa-0 ma-0 pt-0 pb-2">
                <v-list-item class="fonte">
                  <v-avatar color="#333" dark class="mr-2">
                    <v-icon :color="$theme.primary"
                      >mdi-chart-scatter-plot-hexbin</v-icon
                    >
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="">
                      Busca de Relatório
                    </v-list-item-title>
                    <span class="fonte fonteMini grey--text">
                      Crie filtros personalizados abaixo ou pesquise clicando
                      nos que você já criou.
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex class="pt-0" v-if="$vuetify.breakpoint.lgAndUp" xs12>
              <div
                leave-active-class="animate__animated animate__fadeIn"
                class="expande-horizontal wrap animate__animated animate__fadeIn"
              >
                <div class="windows-style-content expande-horizontal wrap">
                  <!-- Filtros -->
                  <v-flex class="pa-3" xs12>
                    <div class="expande-horizontal nowrap">
                      <v-chip
                        :color="
                          period.description === 'today'
                            ? $theme.primary
                            : 'grey lighten-2'
                        "
                        @click="getToday({ description: 'today' })"
                        class="fonte"
                        small
                      >
                        Hoje
                      </v-chip>
                      <v-chip
                        @click="getFilterPersonalized(filter)"
                        :key="index"
                        v-for="(filter, index) in filtersBox"
                        class="fonte pr-0 ml-1"
                        small
                        :color="
                          period.description === filter.description
                            ? $theme.primary
                            : 'grey lighten-2'
                        "
                      >
                        {{ filter.description }}
                        <v-icon @click="removeFilter(index)" class="ml-1"
                          >mdi-close-circle-outline</v-icon
                        >
                      </v-chip>
                      <v-chip
                        :color="$theme.secondary"
                        dark
                        @click="openModalCreateFilter"
                        class="fonte pr-0 ml-1"
                        small
                      >
                        criar filtro
                        <v-icon class="ml-1">mdi-plus-circle-outline</v-icon>
                      </v-chip>
                    </div>
                  </v-flex>
                  <!-- Dados da caixa form -->
                  <v-flex class="px-2" xs12>
                    <div class="expande-horizontal centraliza">
                      <div class="fonte expande-horizontal px-3 column">
                        <h1 class="font-weight-light" :class="balance > 0 ? 'green--text' : 'red--text'">
                          {{ $helper.formataSaldo(balance) }}
                          <v-icon size="31" color="green" v-if="balance > 0">mdi-trending-up</v-icon>
                          <v-icon size="31" color="red" v-if="balance < 0">mdi-trending-down</v-icon>
                          <v-icon size="26" color="orange" v-if="balance == 0">mdi-database-sync-outline</v-icon>
                        </h1>
                        <span v-if="period.description === 'today'" class="black--text fonteMini" style="margin-top: -10px;">balanço de hoje</span>
                        <span v-else class="black--text fonteMini" style="margin-top: -10px;">
                          balanço no período 
                          {{ $moment(period.startDate, 'DD/MM/YYYY').format("DD/MM/YYYY") }}
                          {{ period.endDate ? `à ${$moment(period.endDate, 'DD/MM/YYYY').format("DD/MM/YYYY")}` : '' }} 
                        </span>
                      </div>
                      <v-flex
                        v-if="period.description"
                        class="pa-0 pl-0"
                        xs4
                        md3
                      >
                        <v-card
                          @click="addPeriodToReport"
                          class="fonte expande-horizontal centraliza column pa-3"
                          color="#f7f7f7"
                          hover
                        >
                          <v-icon :color="$theme.primary">mdi-finance</v-icon>
                          <span class="fonte fonteMini text-center">
                            Adicionar ao relatório.
                          </span>
                        </v-card>
                      </v-flex>
                    </div>
                  </v-flex>
                  <v-flex class="mt-3" xs12>
                    <div class="expande-horizontal centraliza">
                      <v-flex xs12 md3>
                        <v-list nav dense color="transparent" class="fonte">
                          <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                <div class="expande-horizontal">
                                  DINHEIRO
                                  <v-spacer></v-spacer>
                                  <v-icon
                                    size="15"
                                    class="mr-1"
                                    color="#333"
                                    >mdi-cash</v-icon
                                  >
                                </div>
                              </v-list-item-title>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas 
                                  </span>
                                  <span class="green--text">
                                    + {{ $helper.formataSaldo(reportValues.total_entrada_dinheiro) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas 
                                  </span>
                                  <span class="red--text">
                                    - {{ $helper.formataSaldo(reportValues.total_saida_dinheiro) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_entrada_dinheiro_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_saida_dinheiro_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle class="py-1">
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span class="green--text font-weight-bold text-uppercase">
                                    CONSOLIDADO
                                  </span>
                                  <span class="green--text font-weight-bold text-uppercase">
                                    = {{ $helper.formataSaldo(reportValues.total_entrada_dinheiro_aprovado - reportValues.total_saida_dinheiro_aprovado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                      <v-flex xs12 md3>
                        <v-list nav dense color="transparent" class="fonte">
                          <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                <div class="expande-horizontal">
                                  DÉBITO
                                  <v-spacer></v-spacer>
                                  <v-icon
                                    size="15"
                                    class="mr-1"
                                    color="#333"
                                    >mdi-credit-card</v-icon
                                  >
                                </div>
                              </v-list-item-title>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas 
                                  </span>
                                  <span class="green--text">
                                    + {{ $helper.formataSaldo(reportValues.total_entrada_debito) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas 
                                  </span>
                                  <span class="red--text">
                                    - {{ $helper.formataSaldo(reportValues.total_saida_debito) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_entrada_debito_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_saida_debito_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle class="py-1">
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span class="green--text font-weight-bold text-uppercase">
                                    CONSOLIDADO
                                  </span>
                                  <span class="green--text font-weight-bold text-uppercase">
                                    = {{ $helper.formataSaldo(reportValues.total_entrada_debito_aprovado - reportValues.total_saida_debito_aprovado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                      <v-flex xs12 md3>
                        <v-list nav dense color="transparent" class="fonte">
                          <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                <div class="expande-horizontal">
                                  CRÉDITO
                                  <v-spacer></v-spacer>
                                  <v-icon
                                    size="15"
                                    class="mr-1"
                                    color="#333"
                                    >mdi-credit-card</v-icon
                                  >
                                </div>
                              </v-list-item-title>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas 
                                  </span>
                                  <span class="green--text">
                                    + {{ $helper.formataSaldo(reportValues.total_entrada_credito) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas 
                                  </span>
                                  <span class="red--text">
                                    - {{ $helper.formataSaldo(reportValues.total_saida_credito) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_entrada_credito_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_saida_credito_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle class="py-1">
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span class="green--text font-weight-bold text-uppercase">
                                    CONSOLIDADO
                                  </span>
                                  <span class="green--text font-weight-bold text-uppercase">
                                    = {{ $helper.formataSaldo(reportValues.total_entrada_credito_aprovado - reportValues.total_saida_credito_aprovado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                      <v-flex xs12 md3>
                        <v-list nav dense color="transparent" class="fonte">
                          <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                <div class="expande-horizontal">
                                  PIX
                                  <v-spacer></v-spacer>
                                  <v-icon
                                    size="15"
                                    class="mr-1"
                                    color="#333"
                                    >mdi-cash</v-icon
                                  >
                                </div>
                              </v-list-item-title>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas 
                                  </span>
                                  <span class="green--text">
                                    + {{ $helper.formataSaldo(reportValues.total_entrada_pix) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas 
                                  </span>
                                  <span class="red--text">
                                    - {{ $helper.formataSaldo(reportValues.total_saida_pix) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Entradas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_entrada_pix_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle>
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span>
                                    Saídas Canceladas
                                  </span>
                                  <span class="orange--text">
                                    {{ $helper.formataSaldo(reportValues.total_saida_pix_cancelado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-divider></v-divider>
                              <v-list-item-subtitle class="py-1">
                                <div class="expande-horizontal" style="justify-content: space-between;">
                                  <span class="green--text font-weight-bold text-uppercase">
                                    CONSOLIDADO
                                  </span>
                                  <span class="green--text font-weight-bold text-uppercase">
                                    = {{ $helper.formataSaldo(reportValues.total_entrada_pix_aprovado - reportValues.total_saida_pix_aprovado) }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                    </div>
                  </v-flex>
                  <v-flex class="pa-3" xs12>
                    <v-flex xs12>
                      <div class="expande-horizontal">
                        <v-flex v-if="false" xs12 md6>
                          <div v-if="false" class="expande-horizontal">
                            <Chart
                              type="doughnut"
                              description="Valor"
                              :labels="['Dinheiro', 'Pix', 'Débito', 'Crédito']"
                              :dataChart="[
                                reportValues.total_dinheiro,
                                reportValues.total_pix,
                                reportValues.total_debito,
                                reportValues.total_credito
                              ]"
                              subtitlePosition="bottom"
                              :id="'chart-2'"
                              :height="200"
                            />
                          </div>
                        </v-flex>
                        <!-- <v-flex xs12>
                          <span>
                            {{ reportValues }}
                          </span>
                        </v-flex> -->
                        
                        <!-- {{ reportValues }} -->
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <v-timeline  style="min-height: 38vh; max-height: 38vh; overflow: auto;" align-top dense>
                        <v-timeline-item
                          v-for="(item, i) in filteredTransactions"
                          :key="item._id"
                          :color="filterStatus(item.current_status).color"
                          icon-color="#333"
                          icon="mdi-fingerprint"
                          class="fonte"
                        >
                          <v-flex xs12 class="pt-1">
                            <div
                              v-if="item.origin === 'sale'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <v-icon color="#333">mdi-eye</v-icon>

                                Entrada de valor para a venda
                                <v-chip outlined small class="font-weight-bold"
                                  >#{{ item.sale.id_transacao }}</v-chip
                                >
                                do cliente
                                {{ item.sale.compra?.cliente?.nome }}
                              </span>
                            </div>
                            <div
                              v-if="item.origin === 'invoice'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <!-- <v-icon color="#333">mdi-eye</v-icon> -->

                                Parcela da conta {{ item.invoice.description }} {{
                                  item.charge.invoice_type === "to_pay"
                                    ? "paga"
                                    : "recebida"
                                }}
                              </span>
                            </div>
                            <div
                              v-if="item.origin === 'stock'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <!-- <v-icon color="#333">mdi-eye</v-icon> -->

                                Entrada de estoque de
                                {{ item.stock.produto.nome }}
                                <v-chip outlined small class="font-weight-bold"
                                  >#{{ item.stock.id_estoque }}</v-chip
                                >
                              </span>
                            </div>
                            <div
                              v-if="item.origin === 'worth'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <!-- <v-icon color="#333">mdi-eye</v-icon> -->

                                Vale para {{ item.charge.client.nome }}
                              </span>
                            </div>
                            <v-list color="transparent" class="pa-0 ma-0" three-line dense>
                              <v-list-item
                                class="fonte"
                                style="max-height: 40px; margin-bottom:3px; background: #e0e0e0; border-radius: 6px;"
                              >
                                <!-- <v-avatar size="21" class="mr-2">
                                  
                                </v-avatar> -->
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{
                                      $helper.formataSaldo(
                                        item.total_without_taxs
                                      )
                                    }}
                                  <span class="grey--text text-lowercase fonteMini">
                                    {{
                                      $moment(item.created_at).format(
                                        "DD/MM/YY [às] HH:mm"
                                      )
                                    }}
                                  </span>
                                  </v-list-item-title>

                                  <span class="not-selectable fonteMini signed-gradient text-lowercase"> <v-icon size="15" class="signed-gradient">mdi-fingerprint</v-icon> {{item._id}}{{ item.path ? `-${item.path}` : '' }}</span>
                                  <span class="mt-1 fonteMini">
                                    <v-icon
                                    size="15"
                                    class="mr-1"
                                    :color="
                                      filterStatus(item.current_status).color
                                    "
                                    >{{
                                      filterStatus(item.current_status).icon
                                    }}</v-icon
                                  >Situação: {{ filterStatus(item.current_status).text }} <v-chip v-if="item.cancel_reason" x-small color="red lighten-1" dark class="pl-1"> <v-icon color="white" class="mr-1" size="12">mdi-alert-outline</v-icon> {{ item.cancel_reason }}</v-chip> <v-chip v-if="item.current_status === 'approved'" dark x-small color="green"> {{ item.user_who_approved.nome }}</v-chip> <v-chip v-if="item.current_status === 'approved'" dark x-small color="green"> {{ item.payment_form }}</v-chip>
                                  </span>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    x-small
                                    tile
                                    depressed
                                    @click="openViewSignature(item)"
                                    color="#f2f2f2"
                                  >
                                    <span v-if="item.current_status === 'canceled'">Cancelado</span>
                                    <span v-if="item.current_status === 'pending'">Pendende de aferição</span>
                                    <span v-if="item.current_status === 'approved'">Aferido {{ $moment(item.approve_date).format('DD/MM/YY [às] HH:mm') }}</span>
                                    <v-icon class="signed-gradient ml-1" size="15">mdi-fingerprint</v-icon>
                                  </v-btn>
                                  <!-- <v-btn
                                    x-small
                                    class="mt-2 elevation-0"
                                    tile
                                    color="#f2f2f2"
                                  >
                                    Aprovar
                                    <v-icon class="signed-gradient" size="15">mdi-check-all</v-icon>
                                  </v-btn> -->
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                        </v-timeline-item>
                        <v-timeline-item
                          color="red"
                          v-if="!filteredTransactions.length"
                        >
                          <v-flex xs12>
                            <div
                              style="min-height: 57vh;"
                              class="expande-horizontal fonte centraliza column"
                            >
                              <v-icon>mdi-target</v-icon>
                              <h3>Nenhuma transação encontrada</h3>
                              <span
                                >Nenhuma transação foi encontrada com base no filtro utilizado.</span
                              >
                            </div>
                          </v-flex>
                        </v-timeline-item>
                      </v-timeline>
                    </v-flex>
                    <v-simple-table
                      class="fonte"
                      v-if="false"
                      style="min-height: 54vh; max-height: 54vh; overflow: auto; width: 100%; border: 1px solid #E5E5E5"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr class="fonte">
                            <!-- <td class="font-weight-bold"> </td> -->
                            <td style="white-space: nowrap">
                              #ID
                            </td>
                            <td style="white-space: nowrap">
                              TOTAL
                            </td>
                            <td style="white-space: nowrap">
                              PAGAMENTO
                            </td>
                            <td style="white-space: nowrap">
                              SIT. PAGAMENTO
                            </td>
                            <td style="white-space: nowrap">
                              DATA
                            </td>
                          </tr>
                        </thead>
                        <tbody style="max-height: 60vh; overflow: auto;">
                          <template v-for="(item, i) in filteredTransactions">
                            <tr
                              :key="item.id"
                              style="cursor: pointer"
                            >
                              <!-- <td style="width: 10px"> <v-checkbox :color="$theme.primary" @click="setMarcado($event, item)" dense hide-details></v-checkbox> </td> -->
                              <td style="width: 10px">
                                <span class="signed-gradient fonteMini">
                                  {{ item._id }}
                                </span>
                              </td>
                              <td
                                class="fonte green--text"
                                @click="openViewSale(item)"
                              >
                                {{
                                  $helper.formataSaldo(item.total_without_taxs)
                                }}
                                <v-icon
                                  v-if="item.current_status === 'canceled'"
                                  size="12"
                                  color="orange"
                                  >mdi-alert-outline</v-icon
                                >
                                <v-icon
                                  v-if="item.current_status === 'approved'"
                                  size="12"
                                  color="green"
                                  >mdi-check</v-icon
                                >
                              </td>
                              <td
                                class="fonte grey--text"
                                @click="openViewSale(item)"
                              >
                                <v-chip x-small dark color="green">{{
                                  item.payment_form
                                }}</v-chip>
                              </td>
                              <td
                                class="fonte grey--text fonteMini"
                                @click="openViewSale(item)"
                              >
                                {{ item.current_status }}
                              </td>
                              <td
                                class="fonte grey--text fonteMini"
                                @click="openViewSale(item)"
                              >
                                {{
                                  $moment(item.created_at).format("DD/MM/YY")
                                }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </div>
              </div>
            </v-flex>
          </div>
        </v-flex>
        <v-flex xs12 md3 class="pa-3 pt-0 pr-0">
          <div class="expande-horizontal windows-style">
            <v-list nav color="transparent" class="pa-0 ma-0 pt-0 pb-2">
              <v-list-item class="fonte">
                <v-avatar color="#333" dark class="mr-2">
                  <v-icon :color="$theme.primary">mdi-finance</v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="">
                    Exportar Relatório
                  </v-list-item-title>
                  <span class="fonte fonteMini grey--text">
                    Monte o relatório ao lado
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div
            style="min-height: 80vh; max-height: 80vh; overflow: auto;"
            class="expande-horizontal windows-style-content"
          >
            <v-flex xs12>
              <v-list class="fonte" color="transparent">
                <template v-for="(r, i) in report">
                  <v-list-item :key="r.period.description + i">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ r.period.description === 'today' ? 'Hoje' : r.period.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $helper.formataSaldo(r.total) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        color="grey"
                        x-small
                        text
                        @click="removePeriodToReport(i)"
                        >remover</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
              <div class="expande-horizontal pa-3">
                <v-btn
                  class="elevation-0"
                  rounded
                  @click="generatePdf"
                  block
                  :color="$theme.primary"
                  >Gerar Pdf</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </div>
    </div>
    <ViewSale :receipt="receipt" :payment_sys_config="payment_sys_config" />
    <v-dialog width="300" v-model="newFilterDialog">
      <v-card class="pa-3 pt-0" width="300">
        <div class="expande-horizontal centraliza">
          <v-list dense>
            <v-list-item class="pa-0 ma-0 fonte">
              <v-avatar :color="$theme.primary" class="mr-2">
                <v-icon color="white">mdi-filter</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Filtro Personalizado
                </v-list-item-title>
                <v-list-item-subtitle>
                  Novo filtro personalizado
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="closeFilterDialog" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <v-flex class="fonte" xs12>
          <v-form ref="formNewFilter">
            <v-flex xs12>
              <v-text-field
                v-model="newFilterForm.description"
                label="Nome do filtro *"
                dense
                autofocus
                placeholder="ex: Janeiro de 2023"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                v-model="newFilterForm.seller"
                label="Vendedor"
                placeholder="ex: Sara"
                item-text="nome"
                return-object
                :items="get_funcionarios.docs"
                dense
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="newFilterForm.startDate"
                label="Data de início *"
                v-mask="['##/##/####']"
                :rules="[
                  v => !!v || 'Campo obrigatório',
                  v => v.length === 10 || 'Data inválida'
                ]"
                placeholder="ex: 01/01/2023"
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="newFilterForm.endDate"
                label="Data Final"
                v-mask="['##/##/####']"
                placeholder="ex: 01/01/2023"
                dense
              ></v-text-field>
            </v-flex>
          </v-form>
          <v-flex xs12>
            <v-btn dark :color="$theme.secondary" @click="createFilter" block>
              Salvar
            </v-btn>
          </v-flex>
        </v-flex>
      </v-card>
    </v-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      filename="hehehe"
      :paginate-elements-by-height="1100"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      @beforeDownload="beforeDownload($event)"
      ref="faturamentoPdf"
    >
      <div slot="pdf-content">
        <section class="pdf-item fonte">
          <div
            id="print-html"
            style=" width: 100%; padding: 6px; display: flex; flex-direction: column; flex-wrap: nowrap;"
          >
            <div
              style="width: 100%; margin-bottom: 30px; padding-bottom: 30px; padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
            >
              <div
                style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
              >
                <div
                  style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
                >
                <div
                  style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
                >
                  <img :style="`height: 50px; border-radius: 3px;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
                  <strong style="text-align: center; font-size: 9pt;">
                      {{ getLoggedUser.tenant[0].nome }}
                    </strong>
                    <span
                      style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                    >
                      CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
                    </span>
                    <span
                      v-if="getLoggedUser.tenant[0].address"
                      style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                    >
                      {{ getLoggedUser.tenant[0].address.formatted_address }}
                    </span>
                    <span
                      v-if="getLoggedUser.tenant[0].phone"
                      style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                    >
                      FONE: {{ getLoggedUser.tenant[0].phone }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="mt-3 fonte centraliza"
                style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
              >
                <h3 class="font-weight-light">Relatório</h3>
              </div>
              <div
                style="margin-top: 50px; margin-bottom: 100px;"
                class="expande-horizontal centraliza pa-6"
              >
                <table>
                  <tr class="tr-table">
                    <td
                      style="text-align: left; font-size: 12pt; padding: 6px;"
                    >
                      <strong>
                        DESCRIÇÃO
                      </strong>
                    </td>
                    <td
                      style="text-align: left; font-size: 12pt; padding: 6px;"
                    >
                      <strong>
                        VALOR
                      </strong>
                    </td>
                  </tr>
                  <tr
                    v-for="r in report"
                    :key="`report-${r.period.description}`"
                    class="tr-table"
                  >
                    <td
                      style="text-align: left; font-size: 12pt; padding: 6px;"
                    >
                      {{ r.period.description }}
                    </td>
                    <td
                      style="text-align: left; font-size: 12pt; padding: 6px;"
                    >
                      {{ $helper.formataSaldo(r.total) }}
                    </td>
                  </tr>
                </table>
              </div>
              <div style="display: flex; min-height: 100px; margin-top: 12px; align-items: center; justify-content: center; flex-direction: column;">
                <div>
                  __________________________________________________
                </div>
                <div style="margin-top: 6px;">
                  Assinatura
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </vue-html2pdf>
  </layout>
</template>

<script>
import MembersPlugin from "@/apps/CosmosPluginStore/plugins/members/index.vue";
import Chart from "../components/Chart";

import LottieAnimation from "lottie-web-vue";
import ViewSale from "@/apps/GestorDePedido/components/ViewSale";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      period: "day",
      options: [],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Caixas",
          path: "/caixas"
        }
      ],
      currentBox: {
        new: false
      },
      filter: {
        search: ""
      },
      newFilterDialog: false,
      newFilterForm: {},
      filtersBox: [],
      filtersRange: [
        {
          text: "Hoje",
          expression: {
            init: this.$moment().startOf("day"),
          }
        },
        {
          text: "Janeiro",
          expression: {
            init: this.$moment().startOf("month"),
            end: this.$moment().endOf("month")
          }
        },
        {
          text: "Fevereiro",
          expression: {
            init: this.$moment()
              .startOf("month")
              .add(1, "month"),
            end: this.$moment()
              .endOf("month")
              .add(1, "month")
          }
        }
      ],
      payment_sys_config: {},
      receipt: {
        show_my_address: { active: true },
        show_my_logo: { active: true },
        show_my_qrcode: { active: true },
        show_client_details: { active: true },
        show_header_text: { active: true, text: "" },
        show_footer_text: { active: true, text: "" }
      },
      report: []
    };
  },
  components: {
    LottieAnimation,
    ViewSale,
    MembersPlugin,
    Chart
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "get_caixa",
      "get_caixas",
      "get_caixas_filtros",
      "get_funcionarios",
      "getPersonalizedLoading"
    ]),
    filteredTransactions() {
      return this.get_caixas.transactions || [];
    },
    balance() {
      return (
        this.reportValues.total_entrada_dinheiro_aprovado +
        this.reportValues.total_entrada_pix_aprovado +
        this.reportValues.total_entrada_debito_aprovado +
        this.reportValues.total_entrada_credito_aprovado -
        this.reportValues.total_saida_dinheiro_aprovado -
        this.reportValues.total_saida_pix_aprovado -
        this.reportValues.total_saida_debito_aprovado -
        this.reportValues.total_saida_credito_aprovado
      )
    },
    reportValues() {
      let total_entrada_dinheiro = 0;
      let total_entrada_pix = 0;
      let total_entrada_debito = 0;
      let total_entrada_credito = 0;

      let total_saida_dinheiro = 0;
      let total_saida_pix = 0;
      let total_saida_debito = 0;
      let total_saida_credito = 0;

      let total_saida_dinheiro_aprovado = 0;
      let total_saida_pix_aprovado = 0;
      let total_saida_debito_aprovado = 0;
      let total_saida_credito_aprovado = 0;

      let total_saida_dinheiro_cancelado = 0;
      let total_saida_pix_cancelado = 0;
      let total_saida_debito_cancelado = 0;
      let total_saida_credito_cancelado = 0;
      
      let total_entrada_dinheiro_aprovado = 0;
      let total_entrada_pix_aprovado = 0;
      let total_entrada_debito_aprovado = 0;
      let total_entrada_credito_aprovado = 0;

      let total_entrada_dinheiro_cancelado = 0;
      let total_entrada_pix_cancelado = 0;
      let total_entrada_debito_cancelado = 0;
      let total_entrada_credito_cancelado = 0;

      const processPayment = payment => {
        switch (payment.payment_form) {
          case "pix":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_pix_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_pix_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_pix_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_pix_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_pix_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_pix_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_pix_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_pix_cancelado += payment.total_without_taxs;
            }
            break;
          case "dinheiro":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_dinheiro_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_dinheiro_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_dinheiro_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_dinheiro_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_dinheiro_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_dinheiro_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_dinheiro_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_dinheiro_cancelado += payment.total_without_taxs;
            }
            break;
          case "debito":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_debito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_debito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_debito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_debito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_debito_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_debito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_debito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_debito_cancelado += payment.total_without_taxs;
            }
            break;
          case "credito":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_credito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_credito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_credito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_credito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_credito_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_credito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_credito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_credito_cancelado += payment.total_without_taxs;
            }
            break;
        }
      };
      if (this.filteredTransactions) {
        this.filteredTransactions.map(transaction =>
          processPayment(transaction)
        );
      }
      return {
        total_entrada_dinheiro,
        total_entrada_pix,
        total_entrada_debito,
        total_entrada_credito,
        total_saida_dinheiro,
        total_saida_pix,
        total_saida_debito,
        total_saida_credito,
        total_saida_dinheiro_aprovado,
        total_saida_pix_aprovado,
        total_saida_debito_aprovado,
        total_saida_credito_aprovado,
        total_saida_dinheiro_cancelado,
        total_saida_pix_cancelado,
        total_saida_debito_cancelado,
        total_saida_credito_cancelado,
        total_entrada_dinheiro_aprovado,
        total_entrada_pix_aprovado,
        total_entrada_debito_aprovado,
        total_entrada_credito_aprovado,
        total_entrada_dinheiro_cancelado,
        total_entrada_pix_cancelado,
        total_entrada_debito_cancelado,
        total_entrada_credito_cancelado
      };
    }
  },
  methods: {
    ...mapActions([
      "listar_caixas_admin",
      "criar_caixa",
      "atualizar_caixa",
      "createGlobalMessage",
      "createConfirmAction",
      "abre_modal_view_gestordepedido",
      "listar_funcionarios"
    ]),
    openViewSignature() {},
    filterStatus(val) {
      if (!val) return { text: "Aguardando", color: "orange" };
      const status = {
        pending: {
          icon: "mdi-clock-outline",
          text: "Aguardando aferição",
          color: "orange lighten-2"
        },
        fine_mesh: {
          icon: "mdi-magnify-scan",
          text: "Malha fina",
          color: "orange"
        },
        fine_mesh: {
          icon: "mdi-magnify-scan",
          text: "Malha fina Checada",
          color: "orange"
        },
        canceled: {
          icon: "mdi-close-circle-outline",
          text: "Cancelado",
          color: "red"
        },
        approved: {
          icon: "mdi-check-circle-outline",
          text: "Aprovado",
          color: "green"
        }
      };
      return status[val];
    },
    getBoxColorStatus(box) {
      const dueBox = this.$moment()
        .startOf("day")
        .isAfter(this.$moment(box.data_abertura).startOf("day"));

      if (box.data_abertura && !box.data_fechamento && !dueBox) {
        return "orange";
      }
      if (box.data_abertura && box.data_fechamento && !dueBox) {
        return "green";
      }
      if (dueBox) {
        return "red";
      }
    },
    generatePdf() {
      var janelaImprimir = window.open("", "", "width=600,height=600");
      setTimeout(() => {
        janelaImprimir.document.open();
        janelaImprimir.document.write(
          "<html><head><title>Relatório</title>"
        );
        janelaImprimir.document.write("<style>");
        janelaImprimir.document.write("table { width: 100%; border: 1px solid #f2f2f2; }");
        janelaImprimir.document.write("tr { width: 100%; border: 1px solid #f2f2f2; }");
        janelaImprimir.document.write("body { -webkit-print-color-adjust: exact; }");
        janelaImprimir.document.write(".tr-table { border-bottom: 1px solid #f2f2f2; margin: 0px; }");
        janelaImprimir.document.write(".bg-texts { background-color: #ffaf15 !important; border-radius: 3px; padding: 3px; padding-right: 6px; padding-left: 6px; color: #333; }");
        janelaImprimir.document.write("@page { size: A4; margin: 2cm; }");
        janelaImprimir.document.write("@media print { .bg-texts { background-color: #ffaf15 !important; } }");
        janelaImprimir.document.write("</style>");
        janelaImprimir.document.write(
          "</head><body>"
        );
        janelaImprimir.document.write('<div id="imprimir-conteudo">');
        janelaImprimir.document.write(
          document.getElementById("print-html").innerHTML
        );
        janelaImprimir.document.write("</div>");
        janelaImprimir.document.write("</body></html>");
        janelaImprimir.document.close();
        janelaImprimir.focus(); // necessary for IE >= 10*/
        janelaImprimir.print();
        if (this.$vuetify.breakpoint.lgAndUp) {
          janelaImprimir.close();
        }
      }, 200);
    },
    sumTotalReceivedOnPeriod(reportValues) {
      let totalReceivedOnPeriod = 0;
      totalReceivedOnPeriod =
        reportValues.total_entrada_dinheiro_aprovado +
        reportValues.total_entrada_pix_aprovado +
        reportValues.total_entrada_debito_aprovado +
        reportValues.total_entrada_credito_aprovado -
        reportValues.total_saida_dinheiro_aprovado -
        reportValues.total_saida_pix_aprovado -
        reportValues.total_saida_debito_aprovado -
        reportValues.total_saida_credito_aprovado;
      return totalReceivedOnPeriod;
    },
    addPeriodToReport() {
      this.report.push({
        period: this.period,
        reportValues: this.reportValues,
        total: this.sumTotalReceivedOnPeriod(this.reportValues)
      });
    },
    removePeriodToReport(index) {
      this.report.splice(index, 1);
    },
    openModalCreateFilter() {
      this.newFilterDialog = true;
      this.newFilterForm = {};
    },
    closeFilterDialog() {
      this.newFilterDialog = false;
      this.newFilterForm = {};
    },
    openBox(box) {
      this.currentBox = box;
    },
    closeBox() {
      this.currentBox = {};
    },
    deleteBox(item) {
      this.createConfirmAction({
        message: "Deseja realmente excluir esse caixa?",
        icon: "mdi-close-circle",
        action: "excluir_caixa",
        action_value: item
      });
    },
    clearFilters() {
      this.get_caixas_filtros.date = null;
      this.listar_caixas_admin();
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    },
    getToday(period) {
      this.period = period;
      const startDate = this.$moment()
        .startOf("day")
        .format("YYYY-MM-DD");
      const endDate = this.$moment()
        .endOf("day")
        .format("YYYY-MM-DD");
      let periods;
      if (period.description === 'today') {
        periods = [startDate];
      } else {
        periods = [startDate, endDate];
      }
      this.get_caixas_filtros.date = periods;
      this.listar_caixas_admin();
    },
    createFilter() {
      if (this.$refs.formNewFilter.validate()) {
        let filters = JSON.parse(localStorage.filtersReport || "[]");
        filters.push(this.newFilterForm);
        localStorage.filtersReport = JSON.stringify(filters);
        this.newFilterDialog = false;
        this.newFilterForm = {};
        this.listFiltersBox();
      }
    },
    listFiltersBox() {
      let filters = JSON.parse(localStorage.filtersReport || "[]");
      this.filtersBox = filters;
    },
    removeFilter(index) {
      let filters = JSON.parse(localStorage.filtersReport || "[]");
      filters.splice(index, 1);
      localStorage.filtersReport = JSON.stringify(filters);
      this.listFiltersBox();
    },
    getValueOnPeriodByTransaction(transaction) {
      let totalReceivedOnPeriod = 0;
      let total_dinheiro = 0;
      let total_pix = 0;
      let total_debito = 0;
      let total_credito = 0;
      let total_troco = 0;

      const verifyIfBeInScopeDate = date => {
        const startDate = this.$moment(this.period.startDate, "DD/MM/YYYY")
          .startOf("day")
          .format();
        const endDate = this.$moment(this.period.endDate, "DD/MM/YYYY")
          .endOf("day")
          .format();
        const isInScopeDate = this.$moment(date).isBetween(startDate, endDate);
        console.log("datestart", startDate);
        console.log("dateend", endDate);
        return isInScopeDate;
      };

      const processPayment = payment => {
        switch (payment.forma_de_pagamento) {
          case "pix":
            if (verifyIfBeInScopeDate(payment.data)) {
              total_pix += payment.valor;
            }
            break;
          case "dinheiro":
            if (verifyIfBeInScopeDate(payment.data)) {
              total_dinheiro += payment.valor;
            }
            break;
          case "debito":
            if (verifyIfBeInScopeDate(payment.data)) {
              total_debito += payment.valor;
            }
            break;
          case "credito":
            if (verifyIfBeInScopeDate(payment.data)) {
              total_dinheiro += payment.valor;
            }
            total_credito += payment.valor;
            break;
          case "troco":
            if (verifyIfBeInScopeDate(payment.data)) {
              total_troco += payment.valor;
            }
            break;
        }
      };
      transaction.historico_de_pagamento.map(payment =>
        processPayment(payment)
      );
      totalReceivedOnPeriod =
        total_pix + total_credito + total_debito + total_dinheiro - total_troco;
      return totalReceivedOnPeriod;
    },
    getFilterPersonalized(period) {
      this.period = period;
      const startDate = this.$moment(period.startDate, "DD/MM/YYYY")
        .startOf("day")
        .utc();
      const endDate = this.$moment(period.endDate, "DD/MM/YYYY").utc();
      let periods = [];
      if (period.endDate) {
        periods = [startDate, endDate];
      } else {
        periods = [startDate];
      }
      this.get_caixas_filtros.date = periods;
      if (period.seller && period.seller._id) {
        this.get_caixas_filtros.seller = { _id: period.seller._id };
      } else {
        this.get_caixas_filtros.seller = false;
      }
      this.listar_caixas_admin();
    },
    openViewSale(sale) {
      if (sale.tipo_de_transacao === "compra") {
        this.abre_modal_view_gestordepedido(sale);
      }
    }
  },
  created() {
    this.listar_funcionarios();
    this.getToday({ description: 'today' });
    this.getConfigPaymentSys();
    this.getConfigReceipt();
    this.listFiltersBox();
  }
};
</script>

<style scoped>
.item-selected {
  background: #f2f2f2 !important;
}
.item-new {
  background: #e1673c !important;
  color: #fff !important;
}

.list-categories::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.list-categories::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.list-categories::-webkit-scrollbar-thumb {
  background-color: #e1673c; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.windows-style {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #f2f2f2;
}
.windows-style-content {
  border-bottom: 1px solid #e0e0e0;
  background: #f2f2f2;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
</style>
